@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
  .date{
    color: black !important;
  }
}

body {
  background: #83a5b5;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(53, 53, 53);
}

.resume-header{
  text-align: center;
  margin-bottom: 11em;
}
.resume-header h1{
  font-size: 6em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: .1em;
}

.resume-sub-title{
  margin-top: 2em;
  margin-bottom: 2.3em;
  font-size: 4em;
  color:rgb(223, 222, 222);
}
.resume-bio{
  font-size:1.1em;
  margin-top:1em;
  font-style:italic;
}

.Resume {
  text-align: center;
}

.resume-header p{
  font-size: 1.5em;
}

.Resume-logo {
  height: 40vmin;
  pointer-events: none;
}

.Resume-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Resume-link {
  color: #61dafb;
}


.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  color: rgb(255, 255, 255);
}

#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.workButton {
  background-color: #A5B583;
}

.workButton:hover {
  background-color: #64704c;
}

.schoolButton {
  background-color: #B583A5;
}

.schoolButton:hover {
  background-color: #7a516d;
}

.wrapper {
  min-height:100%;
  margin-bottom: -50px;
  text-align:center;
  background: white;
  font-size: 1.5em;
  padding-top: 1em;
}


.footer,
.push{
  height:50px;
}